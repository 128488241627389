import { Countries } from "./Countries"

export interface FormInputValidation {
  type:
    | "required"
    | "email"
    | "min"
    | "max"
    | "match"
    | "complete"
    | "requiredIfOtherFieldInList"
    | "postal"
    | "regex"
    | "phone"
    | "latin"
  value?: number | string | string[]
  otherField?: string
}

export interface FormInputState {
  label: string
  value: any
  error?: string
  type?: "checkbox" | "input"
  validation?: FormInputValidation[]
  complete?: boolean
  errMsgOverride?: string
}

export const validateFullName = (name: string) => {
  const regex = RegExp(".+")
  return regex.test(name)
}

export const validatePhone = (field: FormInputState) => {
  const regex = /^[^a-zA-Z]*$/
  const valid = regex.test(field.value)

  if (!valid) {
    field.error = field.errMsgOverride || `Phone number can not contain letters`
  }

  return regex.test(field.value)
}

export const validateLatinScript = (field: FormInputState) => {
  // This regex contains unicode property escapes which aren't supported in IE and some older browsers
  // so, we will skip this validation for such browsers
  try {
    const regex = /^[\p{Script=Latin}0-9\s.,!@#$%^&*()_+-=:;'<>/?\[\]{}|\\`~]*$/u

    const valid = regex.test(field.value)

    if (!valid) {
      field.error =
        field.errMsgOverride ||
        `This field can not contain non-latin alphabetic characters`
    }

    return regex.test(field.value)
  } catch (error) {
    return true
  }
}

export const validateEmail = (field: FormInputState) => {
  const regex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  const valid = regex.test(field.value)
  if (!valid) {
    field.error = field.errMsgOverride || `Email is not valid`
  }
  return valid
}

const validateRequired = (field: FormInputState): boolean => {
  const valid =
    field.value !== undefined && field.value !== null && field.value !== ""
  if (!valid) {
    console.log(`${field.label} is required`)
    field.error = field.errMsgOverride || `${field.label} is required`
  }
  return valid
}

const validatePostal = (
  field: FormInputState,
  otherField: string,
  inputs: Record<string, FormInputState>
): boolean => {
  const country = inputs[otherField].value

  const countryInfo = Countries.get(country)

  if (
    !countryInfo ||
    !countryInfo.postalRegex ||
    countryInfo.postalRegex === ""
  ) {
    return true
  }

  const ex = new RegExp(countryInfo.postalRegex)
  const valid = ex.test(field.value)
  if (!valid) {
    field.error = `${field.label} is invalid. ${countryInfo.postalFormat}`
  }
  return valid
}

const validateRegex = (
  field: FormInputState,
  regex?: string,
  msg?: string
): boolean => {
  if (!regex || regex === "") {
    return true
  }

  const ex = new RegExp(regex)
  const valid = ex.test(field.value)
  if (!valid) {
    field.error = msg || `${field.label} is invalid`
  }
  return valid
}

const validateComplete = (field: FormInputState): boolean => {
  const valid = field.complete === true
  if (!valid) {
    field.error = field.errMsgOverride || `${field.label} is incomplete`
  }
  return valid
}

const validateMin = (field: FormInputState, min: number): boolean => {
  let valid = true
  if (typeof field.value === "string") {
    valid = field.value.length >= min
    if (!valid) {
      field.error =
        field.errMsgOverride || `${field.label} must be ${min} characters`
    }
  } else {
    valid = field.value >= min
    if (!valid) {
      field.error =
        field.errMsgOverride || `${field.label} must be at least ${min}`
    }
  }

  return valid
}

const validateMax = (field: FormInputState, max: number): boolean => {
  let valid = true
  if (typeof field.value === "string") {
    valid = field.value.length <= max
    if (!valid) {
      field.error =
        field.errMsgOverride ||
        `${field.label} must less than ${max + 1} characters`
    }
  } else {
    valid = field.value <= max
    if (!valid) {
      field.error =
        field.errMsgOverride || `${field.label} must be less than ${max + 1}`
    }
  }

  return valid
}

const validateMatch = (
  field: FormInputState,
  matchField: string,
  inputs: Record<string, FormInputState>
): boolean => {
  const valid = field.value === inputs[matchField].value
  if (!valid) {
    field.error =
      field.errMsgOverride ||
      `${field.label} must match ${inputs[matchField].label}`
  }

  return valid
}

const validateRequiredIfOtherFieldInList = (
  field: FormInputState,
  list: string[],
  otherField: string,
  inputs: Record<string, FormInputState>
): boolean => {
  if (list.includes(inputs[otherField]?.value) && !field.value) {
    field.error = `Vat number is required`
    return false
  }
  return true
}

export const isFieldValid = (
  field: FormInputState,
  inputs: Record<string, FormInputState>
): boolean => {
  field.error = ""
  if (!field.validation || field.validation.length === 0) {
    return true
  }
  return field.validation.every(validaton => {
    switch (validaton.type) {
      case "required":
        return validateRequired(field)
      case "email":
        return validateEmail(field)
      case "phone":
        return validatePhone(field)
      case "latin":
        return validateLatinScript(field)
      case "min":
        return validateMin(field, validaton.value as number)
      case "max":
        return validateMax(field, validaton.value as number)
      case "complete":
        return validateComplete(field)
      case "match":
        return validateMatch(field, validaton.value as string, inputs)
      case "postal":
        return validatePostal(field, validaton.otherField!, inputs)
      case "regex":
        return validateRegex(
          field,
          validaton.value as string,
          validaton.otherField
        )
      case "requiredIfOtherFieldInList":
        return validateRequiredIfOtherFieldInList(
          field,
          validaton.value as string[],
          validaton.otherField!,
          inputs
        )
    }
  })
}
