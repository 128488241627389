import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
  ChangeEvent,
} from "react"
import { isFieldValid, FormInputState } from "../utils/validations"

export interface FormStateType {
  inputs: Record<string, FormInputState>
  additionalRequiredChecks: (inputs: Record<string, FormInputState>) => boolean
  skipValidation?: (field: string) => boolean
}

export interface FormActionsType {
  setFormData: Function
  uploadHandler: Function
  checkboxHandler: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void
  selectHandler: (e: ChangeEvent<HTMLSelectElement>) => void
  inputHandler: (e: ChangeEvent<HTMLInputElement> | FocusEvent) => void
  setFormAdditionalReqChecks: Function
  setSkipValidationFunction: (func: (field: string) => boolean) => void
  isValid: () => boolean
  resetForm: () => void
}

export interface FormContextType {
  formState: FormStateType
  formActions: FormActionsType
}

const formReducer = (state: FormStateType, action: any) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.name]: {
            ...state.inputs[action.name],
            value: action.value,
            error: action.error,
            complete: action.complete,
            errMsgOverride: action.errMsgOverride,
          },
        },
      }

    case "CHECKBOX_CHANGE":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.checked, error: action.error },
        },
      }

    case "SELECT_CHANGE":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.name]: {
            value: action.value,
            error: action.error,
            complete: action.complete,
            errMsgOverride: action.errMsgOverride,
          },
        },
      }
    case "FILE_CHANGE":
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.name]: { value: action.value },
        },
      }
    case "SET_REQ_CHECKS":
      return {
        ...state,
        additionalRequiredChecks: action.additionalRequiredChecks,
      }
    case "SET_SKIP_VALIDATION":
      return {
        ...state,
        skipValidation: action.skipValidation,
      }
    case "SET_DATA":
      return {
        ...state,
        inputs: Object.keys(action.inputs).reduce(
          (acc, curr) => {
            // note: this logic is in place so we can set the form data
            // with an existing address
            if (
              typeof action.inputs === "object" &&
              action.inputs !== null &&
              action.inputs[curr] &&
              action.inputs[curr].hasOwnProperty("value") &&
              !action.inputs[curr].value.length
            ) {
              return {
                ...acc,
              }
            }
            return {
              ...acc,
              [curr]: {
                ...state.inputs[curr],
                value: action.inputs[curr],
              },
            }
          },
          { ...state.inputs }
        ),
      }
    default:
      return state
  }
}
export const FormContext = createContext()

const FormContextProvider = ({
  initialInputs,
  additionalRequiredChecks,
  skipValidation,
  children,
}: {
  initialInputs?: Record<string, FormInputState>
  additionalRequiredChecks?: (inputs: Record<string, FormInputState>) => boolean
  skipValidation?: (field: FormInputState) => boolean
  children: any
}) => {
  const [state, dispatch] = useReducer(formReducer, {
    inputs: initialInputs ? JSON.parse(JSON.stringify(initialInputs)) : {},
    additionalRequiredChecks,
  })

  const inputHandler = (
    e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement>
  ) => {
    const field = state.inputs[e.target.name] as FormInputState

    field.value = field.type === "checkbox" ? e.target.checked : e.target.value

    field.errMsgOverride = e.target.errMsgOverride
    field.error = e.target.error
    field.complete = e.target.complete
    if (e.type === "blur") {
      //validate
      isFieldValid(field, state.inputs)
    }

    dispatch({
      type: "INPUT_CHANGE",
      name: e.target.name,
      value: field.value,
      error: field.error,
      complete: field.complete,
      errMsgOverride: field.errMsgOverride,
    })
  }

  const selectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    const field = state.inputs[e.target.name]

    field.value = e.target.value

    if (e.type === "blur") {
      //validate
      isFieldValid(field, state.inputs)
    }

    dispatch({
      type: "SELECT_CHANGE",
      name: e.target.name,
      value: field.value,
      error: field.error,
      complete: field.complete,
      errMsgOverride: field.errMsgOverride,
    })
  }

  const checkboxHandler = useCallback(e => {
    dispatch({
      type: "CHECKBOX_CHANGE",
      name: e.target.name,
      checked: e.target.checked,
    })
  }, [])

  const uploadHandler = useCallback(e => {
    dispatch({
      type: "FILE_CHANGE",
      name: e.target.name,
      value: e.target.files[0],
    })
  }, [])

  const setFormData = useCallback((inputData, formValidity) => {
    let data = {}
    for (const key in inputData) {
      data = {
        ...data,
        [key]: inputData[key],
      }
      //clear errors
      dispatch({
        type: "INPUT_CHANGE",
        name: key,
        value: inputData[key],
        error: "",
      })
    }

    dispatch({
      type: "SET_DATA",
      inputs: data,
    })
  }, [])

  const setFormAdditionalReqChecks = useCallback(
    (
      additionalRequiredChecks?: (
        inputs: Record<string, FormInputState>
      ) => boolean
    ) => {
      dispatch({
        type: "SET_REQ_CHECKS",
        additionalRequiredChecks,
      })
    },
    []
  )

  const setSkipValidationFunction = useCallback(
    (skipValidation: (field: string) => boolean) => {
      dispatch({
        type: "SET_SKIP_VALIDATION",
        skipValidation,
      })
    },
    []
  )
  const resetForm = () => {
    dispatch({
      type: "SET_DATA",
      inputs: initialInputs ? JSON.parse(JSON.stringify(initialInputs)) : {},
    })
  }

  const isValid = (): boolean => {
    Object.keys(state.inputs).forEach((key: string) => {
      const field = state.inputs[key]
      if (!state.skipValidation || !state.skipValidation(key)) {
        isFieldValid(field, state.inputs)
      } else {
        field.error = ""
      }

      dispatch({
        type: "INPUT_CHANGE",
        name: key,
        value: field.value,
        error: field.error,
      })
    })
    if (
      !state.additionalRequiredChecks ||
      state.additionalRequiredChecks(state.inputs)
    ) {
    }
    return (
      (!state.additionalRequiredChecks ||
        state.additionalRequiredChecks(state.inputs)) &&
      Object.keys(state.inputs).every(k => !state.inputs[k].error)
    )
  }
  const actions = {
    setFormData,
    uploadHandler,
    checkboxHandler,
    selectHandler,
    inputHandler,
    setFormAdditionalReqChecks,
    setSkipValidationFunction,
    isValid,
    resetForm,
  }

  return (
    <FormContext.Provider value={{ formState: state, formActions: actions }}>
      {children}
    </FormContext.Provider>
  )
}

const useForm: Function = (): FormContextType =>
  useContext(FormContext) as FormContextType

export { FormContextProvider, useForm }
